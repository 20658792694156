import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NowPage = () => (
  <Layout slugLine="elsewhere on the internet" backLink={{path:'/', name: 'home'}}>
    <SEO title="Coleman Collins" />
    <section className="article">
        <h2>Here's a list of places you can find me online</h2>
        <p>I don't update any of these with much consistency—I try and keep the important stuff here on this site. But in case you'd like see my instagram stories about cooking or to add me to your professional network or whatever:</p>
        <ul>
            <li>Twitter: <a href="https://twitter.com/colemancollins_" target="_blank" rel="noopener noreferrer">@colemancollins_</a></li>
            <li>Instagram: <a href="https://instagram.com/colemancollins_" target="_blank" rel="noopener noreferrer">@colemancollins_</a></li>
            <li>Github: <a href="https://github.com/colemancollins" target="_blank" rel="noopener noreferrer">colemancollins</a></li>
            <li>Dribbble: <a href="https://dribbble.com/colemancollins" target="_blank" rel="noopener noreferrer">colemancollins</a></li>
            <li>Codepen: <a href="https://codepen.io/colemancollins" target="_blank" rel="noopener noreferrer">colemancollins</a></li>
            <li>LinkedIn: <a href="https://linkedin.com/in/colemancollins" target="_blank" rel="noopener noreferrer">Coleman Collins</a></li>
        </ul>
  </section>
  </Layout>
)

export default NowPage;
